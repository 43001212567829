import axios, { AxiosResponse } from "axios";

const API_PATH = "/api/v1";

const setAdditionalParams = (params: URLSearchParams, additionalParams: { [key: string]: any } = {}) => {
  Object.keys(additionalParams).forEach(key => {
    if (additionalParams[key]) {
      params.append(key, additionalParams[key]);
    }
  });
};

export class MethodOptions {
  headers?: { [key: string]: string } = {};
  params?: { [key: string]: string } = {};

  constructor(options: MethodOptions) {
    if (options.headers) {
      this.headers = options.headers;
    }
    if (options.params) {
      this.params = options.params;
    }
  }
}

export interface InstitutionStatusRequest {
  operatingStatus?: string;
  closingReason?: string;
}

export interface PermissionTicketResponse {
  ticket?: string;
  authzServerLocation?: string;
}

export interface AddressRequest {
  id?: string;
  roleOfAddress?: string;
  dependencyName?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  phone?: string;
  fax?: string;
  email?: string;
  website?: string;
}

export interface InstitutionRequest {
  name?: string;
  secondName?: string;
  state?: string;
  countryCode?: string;
  type?: string;
  registrationAuthorityId?: string;
  openingDate?: string;
  closingDate?: string;
  closingReason?: string;
  addresses?: AddressRequest[];
}

export interface AuthenticationCertificateRequestDto {
  username?: string;
  email?: string;
}

export interface AuthenticationCertificateResponseDto {
  keystore?: string;
  password?: string;
}

export interface RegistrationAuthorityAdminResponse {
  id?: string;
  email?: string;
  createdAt?: string;
  createdBy?: string;
}

export interface RegistrationAuthorityResponse {
  id?: string;
  name?: string;
  children?: RegistrationAuthorityResponse[];
  admins?: RegistrationAuthorityAdminResponse[];
  createdAt?: string;
  createdBy?: string;
}

export interface RegistrationAuthorityRequest {
  name?: string;
  parentId?: string;
}

export interface RegistrationAuthorityAdminRequest {
  email?: string;
}

export interface InstitutionDetailedResponse {
  id?: string;
  name?: string;
  secondName?: string;
  state?: string;
  countryCode?: string;
  type?: string;
  openingDate?: string;
  operatingStatus?: string;
  closingDate?: string;
  closingReason?: string;
  addresses?: AddressRequest[];
  registrationAuthority?: RegistrationAuthorityResponse;
  issuerConfigId?: string;
  admins?: InstitutionUserResponse[];
  issuers?: InstitutionUserResponse[];
}

export interface InstitutionUserResponse {
  userId?: string;
  email?: string;
  startDate?: string;
  endDate?: string;
  approvalState?: number;
  institutionId?: string;
  createdAt?: string;
  createdBy?: string;
}

export interface InstitutionSplitDto {
  institutionToCreate?: InstitutionRequest;
  memberActions?: MemberActionDto[];
}

export interface MemberActionDto {
  memberId?: string;
  action?: string;
}

export interface ApprovalProcessResponseDto {
  result?: string;
  reason?: string;
}

export interface InstitutionMerge {
  registrationAuthorityId?: string;
  idOfInstitutionToKeep?: string;
  idsToMerge?: string[];
  moveAdminsToNewInstitution?: any;
  moveIssuersToNewInstitution?: any;
  institutionToCreate?: InstitutionRequest;
}

export interface Institution {
  id?: string;
  name?: string;
  secondName?: string;
}

export interface InstitutionUserShortResponse {
  id?: string;
  institution?: Institution;
}

export interface RegistrationAuthority {
  id?: string;
  name?: string;
}

export interface RegistrationAuthorityAdmin {
  id?: string;
  registrationAuthority?: RegistrationAuthority;
}

export interface UserInfoResponse {
  institutionAdmins?: InstitutionUserShortResponse[];
  institutionIssuers?: InstitutionUserShortResponse[];
  registrationAuthorityAdmins?: RegistrationAuthorityAdmin[];
}

export interface InstitutionIssuerConfigDto {
  id?: string;
  name?: string;
  secondName?: string;
  issuerConfigId?: string;
}

export interface InstitutionBaseResponse {
  id?: string;
  name?: string;
  secondName?: string;
  state?: string;
  countryCode?: string;
  type?: string;
  openingDate?: string;
  operatingStatus?: string;
  closingDate?: string;
  closingReason?: string;
  addresses?: AddressRequest[];
  registrationAuthority?: RegistrationAuthorityResponse;
}

export interface Audit {
  id?: string;
  entity?: string;
  operation?: string;
  modifiedBy?: string;
  modifiedAt?: string;
  payload?: string;
}

export interface AnalysisResponse {
  registrationAuthorityId?: string;
  registrationAuthorityName?: string;
  startDate?: string;
  endDate?: string;
  numberOfSignatures?: number;
  numberOfRevocations?: number;
  institution?: Institution;
}

export interface PageAnalysisResponse {
  totalElements?: number;
  totalPages?: number;
  size?: number;
  content?: AnalysisResponse[];
  number?: number;
  sort?: SortObject;
  first?: any;
  last?: any;
  numberOfElements?: number;
  pageable?: PageableObject;
  empty?: any;
}

export interface PageableObject {
  offset?: number;
  sort?: SortObject;
  pageNumber?: number;
  paged?: any;
  pageSize?: number;
  unpaged?: any;
}

export interface SortObject {
  empty?: any;
  sorted?: any;
  unsorted?: any;
}


export interface InstitutionsInstitutionIdOperatingStatusPutArgs {
  institutionId: string;
}

export function institutionsInstitutionIdOperatingStatusPut(args: InstitutionsInstitutionIdOperatingStatusPutArgs, body?: InstitutionStatusRequest, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/operating-status";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "put",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsIdGetArgs {
  id: string;
}

export function institutionsIdGet(args: InstitutionsIdGetArgs, options: MethodOptions = {}): Promise<AxiosResponse<InstitutionDetailedResponse>> {
  const { id } = args;
  let url = (API_PATH ?? "") + "/institutions/{id}";
  url = url.split("{" + "id" + "}").join(encodeURIComponent(String(id)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export interface InstitutionsIdPutArgs {
  id: string;
}

export function institutionsIdPut(args: InstitutionsIdPutArgs, body?: InstitutionRequest, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { id } = args;
  let url = (API_PATH ?? "") + "/institutions/{id}";
  url = url.split("{" + "id" + "}").join(encodeURIComponent(String(id)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "put",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsIdDeleteArgs {
  id: string;
}

export function institutionsIdDelete(args: InstitutionsIdDeleteArgs, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { id } = args;
  let url = (API_PATH ?? "") + "/institutions/{id}";
  url = url.split("{" + "id" + "}").join(encodeURIComponent(String(id)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "delete",
    params,
    headers: { ...options.headers }
  });
}

export interface InstitutionsGetArgs {
  search?: string;
  issuerConfigId?: string;
  registrationAuthorityId?: string;
  page?: number;
  size?: number;
  sort?: string[];
}

export function institutionsGet(args: InstitutionsGetArgs, options: MethodOptions = {}): Promise<AxiosResponse<InstitutionBaseResponse>> {
  const { search, issuerConfigId, registrationAuthorityId, page, size, sort } = args;
  const url = (API_PATH ?? "") + "/institutions";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  if (search !== undefined) {
    params.append("search", search as any);
  }
  if (issuerConfigId !== undefined) {
    params.append("issuerConfigId", issuerConfigId as any);
  }
  if (registrationAuthorityId !== undefined) {
    params.append("registrationAuthorityId", registrationAuthorityId as any);
  }
  if (page !== undefined) {
    params.append("page", page as any);
  }
  if (size !== undefined) {
    params.append("size", size as any);
  }
  if (sort !== undefined) {
    (sort || []).forEach(item => {
      params.append("sort", item as any);
    });
  }
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export function institutionsPost(body?: InstitutionRequest, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const url = (API_PATH ?? "") + "/institutions";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsInstitutionIdSplitPostArgs {
  institutionId: string;
}

export function institutionsInstitutionIdSplitPost(args: InstitutionsInstitutionIdSplitPostArgs, body?: InstitutionSplitDto, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/split";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsInstitutionIdIssuersPostArgs {
  institutionId: string;
}

export function institutionsInstitutionIdIssuersPost(args: InstitutionsInstitutionIdIssuersPostArgs, body?: InstitutionUserResponse, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/issuers";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsInstitutionIdIssuersMemberIdApprovalPostArgs {
  institutionId: string;
  memberId: string;
}

export function institutionsInstitutionIdIssuersMemberIdApprovalPost(args: InstitutionsInstitutionIdIssuersMemberIdApprovalPostArgs, body?: ApprovalProcessResponseDto, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId, memberId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/issuers/{memberId}/approval";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  url = url.split("{" + "memberId" + "}").join(encodeURIComponent(String(memberId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsInstitutionIdAdminsPostArgs {
  institutionId: string;
}

export function institutionsInstitutionIdAdminsPost(args: InstitutionsInstitutionIdAdminsPostArgs, body?: InstitutionUserResponse, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/admins";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsInstitutionIdAdminsMemberIdApprovalPostArgs {
  institutionId: string;
  memberId: string;
}

export function institutionsInstitutionIdAdminsMemberIdApprovalPost(args: InstitutionsInstitutionIdAdminsMemberIdApprovalPostArgs, body?: ApprovalProcessResponseDto, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId, memberId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/admins/{memberId}/approval";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  url = url.split("{" + "memberId" + "}").join(encodeURIComponent(String(memberId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export function institutionsMergePost(body?: InstitutionMerge, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const url = (API_PATH ?? "") + "/institutions/merge";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export function institutionsImportPost(body?: InstitutionRequest, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const url = (API_PATH ?? "") + "/institutions/import";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface InstitutionsInstitutionIdIssuersMemberIdDeleteArgs {
  institutionId: string;
  memberId: string;
}

export function institutionsInstitutionIdIssuersMemberIdDelete(args: InstitutionsInstitutionIdIssuersMemberIdDeleteArgs, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId, memberId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/issuers/{memberId}";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  url = url.split("{" + "memberId" + "}").join(encodeURIComponent(String(memberId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "delete",
    params,
    headers: { ...options.headers }
  });
}

export interface InstitutionsInstitutionIdAdminsMemberIdDeleteArgs {
  institutionId: string;
  memberId: string;
}

export function institutionsInstitutionIdAdminsMemberIdDelete(args: InstitutionsInstitutionIdAdminsMemberIdDeleteArgs, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { institutionId, memberId } = args;
  let url = (API_PATH ?? "") + "/institutions/{institutionId}/admins/{memberId}";
  url = url.split("{" + "institutionId" + "}").join(encodeURIComponent(String(institutionId)));
  url = url.split("{" + "memberId" + "}").join(encodeURIComponent(String(memberId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "delete",
    params,
    headers: { ...options.headers }
  });
}

export function usersMtlsPost(body?: AuthenticationCertificateRequestDto, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const url = (API_PATH ?? "") + "/users/mtls";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export function usersMeGet(options: MethodOptions = {}): Promise<AxiosResponse<UserInfoResponse>> {
  const url = (API_PATH ?? "") + "/users/me";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export function usersInstitutionsGet(options: MethodOptions = {}): Promise<AxiosResponse<InstitutionIssuerConfigDto[]>> {
  const url = (API_PATH ?? "") + "/users/institutions";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export interface RegistrationAuthoritiesGetArgs {
  page?: number;
  size?: number;
  sort?: string[];
}

export function registrationAuthoritiesGet(args: RegistrationAuthoritiesGetArgs, options: MethodOptions = {}): Promise<AxiosResponse<RegistrationAuthorityResponse>> {
  const { page, size, sort } = args;
  const url = (API_PATH ?? "") + "/registration-authorities";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  if (page !== undefined) {
    params.append("page", page as any);
  }
  if (size !== undefined) {
    params.append("size", size as any);
  }
  if (sort !== undefined) {
    (sort || []).forEach(item => {
      params.append("sort", item as any);
    });
  }
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export function registrationAuthoritiesPost(body?: RegistrationAuthorityRequest, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const url = (API_PATH ?? "") + "/registration-authorities";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface RegistrationAuthoritiesRegistrationAuthorityIdAdminsGetArgs {
  registrationAuthorityId: string;
  page?: number;
  size?: number;
  sort?: string[];
}

export function registrationAuthoritiesRegistrationAuthorityIdAdminsGet(args: RegistrationAuthoritiesRegistrationAuthorityIdAdminsGetArgs, options: MethodOptions = {}): Promise<AxiosResponse<RegistrationAuthorityResponse>> {
  const { registrationAuthorityId, page, size, sort } = args;
  let url = (API_PATH ?? "") + "/registration-authorities/{registrationAuthorityId}/admins";
  url = url.split("{" + "registrationAuthorityId" + "}").join(encodeURIComponent(String(registrationAuthorityId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  if (page !== undefined) {
    params.append("page", page as any);
  }
  if (size !== undefined) {
    params.append("size", size as any);
  }
  if (sort !== undefined) {
    (sort || []).forEach(item => {
      params.append("sort", item as any);
    });
  }
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export interface RegistrationAuthoritiesRegistrationAuthorityIdAdminsPostArgs {
  registrationAuthorityId: string;
}

export function registrationAuthoritiesRegistrationAuthorityIdAdminsPost(args: RegistrationAuthoritiesRegistrationAuthorityIdAdminsPostArgs, body?: RegistrationAuthorityAdminRequest, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { registrationAuthorityId } = args;
  let url = (API_PATH ?? "") + "/registration-authorities/{registrationAuthorityId}/admins";
  url = url.split("{" + "registrationAuthorityId" + "}").join(encodeURIComponent(String(registrationAuthorityId)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "post",
    params,
    data: body,
    headers: { "Content-Type": "application/json", ...options.headers }
  });
}

export interface RegistrationAuthoritiesIdGetArgs {
  id: string;
}

export function registrationAuthoritiesIdGet(args: RegistrationAuthoritiesIdGetArgs, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { id } = args;
  let url = (API_PATH ?? "") + "/registration-authorities/{id}";
  url = url.split("{" + "id" + "}").join(encodeURIComponent(String(id)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export interface RegistrationAuthoritiesIdDeleteArgs {
  id: string;
}

export function registrationAuthoritiesIdDelete(args: RegistrationAuthoritiesIdDeleteArgs, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { id } = args;
  let url = (API_PATH ?? "") + "/registration-authorities/{id}";
  url = url.split("{" + "id" + "}").join(encodeURIComponent(String(id)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "delete",
    params,
    headers: { ...options.headers }
  });
}

export interface RegistrationAuthoritiesRegistrationAuthorityIdAdminsIdDeleteArgs {
  registrationAuthorityId: string;
  id: string;
}

export function registrationAuthoritiesRegistrationAuthorityIdAdminsIdDelete(args: RegistrationAuthoritiesRegistrationAuthorityIdAdminsIdDeleteArgs, options: MethodOptions = {}): Promise<AxiosResponse<any>> {
  const { registrationAuthorityId, id } = args;
  let url = (API_PATH ?? "") + "/registration-authorities/{registrationAuthorityId}/admins/{id}";
  url = url.split("{" + "registrationAuthorityId" + "}").join(encodeURIComponent(String(registrationAuthorityId)));
  url = url.split("{" + "id" + "}").join(encodeURIComponent(String(id)));
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  return axios({
    url,
    method: "delete",
    params,
    headers: { ...options.headers }
  });
}

export interface AuditsGetArgs {
  page?: number;
  size?: number;
  sort?: string[];
  search?: string;
}

export function auditsGet(args: AuditsGetArgs, options: MethodOptions = {}): Promise<AxiosResponse<Audit>> {
  const { page, size, sort, search } = args;
  const url = (API_PATH ?? "") + "/audits";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  if (page !== undefined) {
    params.append("page", page as any);
  }
  if (size !== undefined) {
    params.append("size", size as any);
  }
  if (sort !== undefined) {
    (sort || []).forEach(item => {
      params.append("sort", item as any);
    });
  }
  if (search !== undefined) {
    params.append("search", search as any);
  }
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}

export interface AnalysisByRegistrationAuthorityGetArgs {
  search?: string;
  issuerConfigId?: string;
  startDate: string;
  endDate: string;
  registrationAuthorityId?: string;
  page?: number;
  size?: number;
  sort?: string[];
}

export function analysisByRegistrationAuthorityGet(args: AnalysisByRegistrationAuthorityGetArgs, options: MethodOptions = {}): Promise<AxiosResponse<PageAnalysisResponse>> {
  const { search, issuerConfigId, startDate, endDate, registrationAuthorityId, page, size, sort } = args;
  const url = (API_PATH ?? "") + "/analysis/by-registration-authority";
  const params = new URLSearchParams();
  setAdditionalParams(params, options.params);
  if (search !== undefined) {
    params.append("search", search as any);
  }
  if (issuerConfigId !== undefined) {
    params.append("issuerConfigId", issuerConfigId as any);
  }
  if (startDate !== undefined) {
    params.append("startDate", startDate as any);
  }
  if (endDate !== undefined) {
    params.append("endDate", endDate as any);
  }
  if (registrationAuthorityId !== undefined) {
    params.append("registrationAuthorityId", registrationAuthorityId as any);
  }
  if (page !== undefined) {
    params.append("page", page as any);
  }
  if (size !== undefined) {
    params.append("size", size as any);
  }
  if (sort !== undefined) {
    (sort || []).forEach(item => {
      params.append("sort", item as any);
    });
  }
  return axios({
    url,
    method: "get",
    params,
    headers: { ...options.headers }
  });
}
