import React from "react";
import ReactDOM from "react-dom/client";
import "@nbp/dnafe-material-ui/dist/styles/index.scss";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import ModalProvider from "mui-modal-provider";
import { getMUILocale } from "./helpers/mui-locale";
import AxiosErrorInterceptorProvider from "./providers/AxiosErrorInterceptorProvider";
import NBPThemeProvider, { createLocalizedTheme } from "@nbp/dnafe-material-ui/dist/providers/NBPThemeProvider";
import { KeycloakProvider } from "./keycloak";
import "./styles.scss";

declare global {
  interface Window {
    env: any;
  }
}

window.env = window.env || {
  "BASE_PATH": "",
  "FOOTER_LINKS_HOST": "",
  "MATOMO_CLOUD_HOST": "",
  "MATOMO_SITE_ID": "",
  "MATOMO_ENABLED": "false",
  "API_PATH": "",
  "keycloak": {
    "KEYCLOAK_CLIENT_ID": "",
    "KEYCLOAK_REDIRECT_URI": "",
    "KEYCLOAK_REALM": "",
    "KEYCLOAK_URL": ""
  }
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const theme = createLocalizedTheme(getMUILocale());

root.render(
  <React.StrictMode>
    <NBPThemeProvider theme={theme}>
      <ModalProvider>
        <AxiosErrorInterceptorProvider>
          <KeycloakProvider>
            <RouterProvider router={router} />
          </KeycloakProvider>
        </AxiosErrorInterceptorProvider>
      </ModalProvider>
    </NBPThemeProvider>
  </React.StrictMode>
);
